<template>
    <section class="addSite">
        <pageLoader v-if="ploader" :loadingText="'Chargement des données...'"/>
        <!-- header -->
        <div class="page-header">
            <h3 class="page-title"> Créer un nouveau site </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">sites</a></li>
                <li class="breadcrumb-item active" aria-current="page">Créer un nouveau site</li>
                </ol>
            </nav>
        </div>
        <!-- corps de la page -->
        <div class="row">
            <div class="col md-12">
                <div class="card">
                    <div class="card-body">
                        <!-- <form @click.prevent="handleSubmit()" class="forms-sample" > -->
                            <div class="row">
                                <div class="col-md-12">
                                    <add-image
                                        :modal="'addImageCategorie' + ulid "
                                        @saveImage="saveImage(ulid, $event['ulid'])"
                                    />
                                </div>
                            </div>
                            <!-- image du site -->
                            <!-- <img v-if="image.content.webpImage && image" :src="image.content.webpImage" alt="">
                            <img v-else style="max-width: 100%;" src="../../../assets/images/import/default-image.jpg" alt=""> -->

                            <div class="row">
                                <div class="col-md-6">
                                    <h3>A propos du site</h3>
                                    <!-- Nom du site -->
                                    <b-form-group id="name" label="Nom du site" label-for="example-input-1">
                                        <b-form-input
                                        id="example-input-1"
                                        type="text"
                                        name="example-input-1"
                                        v-model="name"
                                        :state="$v.name.$dirty ? !$v.name.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                        Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <!-- Description du site -->
                                    <b-form-group id="description" label="Description" label-for="example-input-1">
                                        <b-form-textarea
                                        id="example-input-1"
                                        name="example-input-1"
                                        v-model="description"
                                        :state="$v.description.$dirty ? !$v.description.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        ></b-form-textarea>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                        Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <!-- type de site -->
                                    <b-form-group id="sitetype" label="Type de site" label-for="example-input-1">
                                        <b-form-input
                                        id="example-input-1"
                                        name="example-input-1"
                                        v-model="sitetype"
                                        :state="$v.sitetype.$dirty ? !$v.sitetype.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                        Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <!-- status -->
                                    <b-form-group id="status" label="Status" label-for="example-input-1">
                                        <b-form-select
                                            v-model="status"
                                            :options="status_options"
                                            class="mb-3"
                                            value-field="status"
                                            text-field="status"
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <h3>URL</h3>
                                    <b-form-group id="canonicalurl" label="canonicalurl" label-for="canonicalurl">
                                        <b-input-group>
                                            <b-form-input
                                            id="canonicalurl"
                                            name="canonicalurl"
                                            v-model="canonicalurl"
                                            :placeholder="canonicalurl"
                                            >
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group id="frontend" label="frontend" label-for="frontend">
                                        <b-input-group>
                                            <b-form-input
                                            id="frontendurl"
                                            name="frontendurl"
                                            v-model="frontendurl"
                                            :placeholder="frontendurl"
                                            >
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group id="backend" label="backend" label-for="backend">
                                        <b-input-group>
                                            <b-form-input
                                            id="backend"
                                            name="backend"
                                            v-model="backendurl"
                                            :placeholder="backendurl"
                                            >
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                            </div>
                            <!-- Administrateurs -->
                            <div class="row mt-4">
                                <div class="col-md-6 col-sm-12">
                                    <div class="d-flex justify-content-between">
                                        <h3>Administrateurs</h3>
                                        <button for="ajouter" class="btn btn-link pointer" @click="modalAddOwner()">Ajouter</button>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <tr>
                                                <th>Nom</th>
                                                <th class="text-right">Actions</th>
                                            </tr>
                                            <tr v-for="owner in owners" :key="owner.id">
                                                <td>{{owner.firstname}} {{owner.lastname}}</td>
                                                <td class="text-right"><i class="fa fa-trash" @click="showAlertDelete(owner.ulid)"></i></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Contact -->
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <h3>Contact</h3>
                                    <!-- Contact email -->
                                    <b-form-group id="contactmail" label="Adresse email" label-for="example-input-1">
                                        <b-form-input
                                        id="example-input-1"
                                        name="example-input-1"
                                        v-model="contactmail"
                                        :state="$v.contactmail.$dirty ? !$v.contactmail.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                        Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <!-- Réseaux sociaux  -->
                                    <!-- <b-form-group id="contactmail" label="Adresse email" label-for="example-input-1">
                                        <b-form-input
                                        id="example-input-1"
                                        name="example-input-1"
                                        v-model="contactmail"
                                        :state="$v.contactmail.$dirty ? !$v.contactmail.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                        Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group> -->
                                </div>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-link mx-4" @click="gotoSites()">
                                    Annuler
                                </button>
                                <button @click="handleSubmit()" class="btn btn-success mx-4">
                                    Enregistrer
                                </button>
                            </div>
                        <!-- </form> -->
                    </div> <!-- /card-body -->
                </div><!-- /card -->
            </div>
        </div>

        <!-- MODALS -->
        <!-- sélections des administrateurs -->
        <b-modal id="modalAddOwner" title="Liste des utilisateurs" hide-footer>
            <div class="table-responsive">
                <table class="table b-table table-striped table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr></tr>
                        <tr v-for="user in ownersInUsers" :key="user.id">
                            <td>
                                <input :id="user.ulid" type="checkbox" v-model="checked_user" :value="user.ulid">
                            </td>
                            <td>{{user.firstname + ' ' + user.lastname}}</td>
                        <div>
                        </div>
                        </tr>
                    </tbody>
                </table>
                    <hr>
                    <div class="d-flex justify-content-end">
                        <div class="btn btn-link" >
                            Annuler
                        </div>
                        <!-- validation des nouveaux owners -->
                        <button class="btn btn-success mx-4" @click="addOwners()">
                            Valider
                        </button>
                    </div>
            </div>
        </b-modal>

    </section>
</template>
<script>
import axios from 'axios'
import {mapState} from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import addImage from '../../../components/functions/add-image.vue'
export default {
    name: 'addSite',
    mixins: [validationMixin],
    components:{addImage},
    data () {
        return {
            // loader
            ploader:true,
            ulid:'',
            // formulaire
            name:'',
            description:'',
            sitetype:'',
            contactmail:'',
            owners:[],
            image:'',
            status:"",
            status_options:["En cours de développement", "Homologation", "Production"],
            // liste de tous les utilisateurs
            users:[],
            checked_user:[],
            axios_base_url:'https://api.ailem.io',
            owner_ulid:'',
            // url
            canonicalurl:'',
            frontendurl:'',
            backendurl:'',
        }
    },
    computed:{
        ...mapState({
            main_api_url: state=> state.globalVariable.main_api_url
        }),
        // récupération de tous les utilisateurs non owners pour l'afficher dans le modal
        ownersInUsers(){
            let ownersUsers = []
            this.$store.state.users.users.map(user => {
                 this.owners.map(owner => {
                    if(owner.ulid !== user.ulid) {
                       ownersUsers.push({
                            ulid: user.ulid,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            checked: true
                        })
                    }
                })
            })
            return ownersUsers
        }
    },
    validations: {
        name:{
            required
        },
        description:{
            required
        },
        sitetype:{
            required
        },
        contactmail:{
            required
        }
    },
    methods:{
        handleSubmit(){
            // vérification des champs vide et comportant des erreurs
            this.$v.$touch()
            if (this.$v.$anyError) {
            return
            }
            //fonction de supression des espaces dans les strings (pour les urls)
            const removeSpaces = str => str.replace(/\s/g, '');
            let body = {
                ulidimage:"",
                owner:this.owner_ulid,
                captchakey:"",
                captchasecret:"",
                captchasiteverifyurl: "",
                name:this.name,
                canonicalurl:removeSpaces(this.canonicalurl),
                frontendurl:removeSpaces(this.frontendurl),
                backendurl: removeSpaces(this.backendurl),
                contactmail: this.contactmail,
                noreplymailsender:this.contactmail,
                sitetype:this.sitetype,
                iscurrentapp:true,
                domainnames:["test.com"],
                status:this.status,
                description:this.description,
                
            }
             if (this.ulid) {
            /**
            *put/companies axios
            */
            let url =`${this.main_api_url}/sites/${this.ulid}`
            let method ="put"
            this.requestSite(method,url,body)
          }else{
            /**
            *post/companies axios
            */
            let url =`${this.main_api_url}/sites`
            let method ="post"
            this.requestSite(method,url,body)
          }
        },
        requestSite(method,url,body){
        // this.ploader= true
          axios[method](url,body,
            {
              headers: {
                "X-AUTH-TOKEN": localStorage.getItem("token")
              },
            }
          )
          .then(
            ()=>{
            //   this.ploader= false
              method == "put"?this.gotoSites():method == "post"?this.$snotify.info("Ajouté avec succès")&&this.gotoSites():alert('il y a une erreur')
            }
          )
          .catch(
            err => {
            //   this.ploader= false
            alert(err)
              console.error(method,err)
            }
          )
      },
        getSite(ulid){
            console.log("ulid dans getsite", ulid);
            axios.get(`${this.main_api_url}/sites/${ulid}`)
            .then(resGetSite => {
                // image
                this.image=resGetSite.data.image
                // infos
                this.name = resGetSite.data.name
                this.description = resGetSite.data.description
                this.sitetype = resGetSite.data.sitetype
                this.status = resGetSite.data.status
                this.owners = resGetSite.data.owner
                this.image = resGetSite.data.image
                this.contactmail = resGetSite.data.contactmail
                // url
                this.canonicalurl = resGetSite.data.canonicalurl
                this.frontendurl = resGetSite.data.frontendurl
                this.backendurl = resGetSite.data.backendurl
                // this.ownersInUsers()
                this.ploader=false
            })
            .catch(errGetSite => {
                errGetSite
                
                console.log('get site', errGetSite);
            })
        },
        // modal ajouter un owner
        modalAddOwner(){
            this.$bvModal.show('modalAddOwner')
        },
        addOwners(){
            this.checked_user.map(ulidUser => {
                axios.put(`${this.main_api_url}/sites/${this.ulid}`,{
                    add_owner:ulidUser
                })
                .then(resAddOwner => {
                    resAddOwner
                    this.ownersInUsers=[]
                    this.getSite(this.ulid)
                    this.$bvModal.hide('modalAddOwner')
                })
                .catch(errAddOwner => console.log(errAddOwner))
            })
        },
        showAlertDelete(ulidUser){
            this.$swal({
                title: 'Êtes-vous sûr?',
                text: 'Voulez-vous supprimer cet utilisateur des propriétaires du site?',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, Supprimer',
                cancelButtonText: 'Annuler',
            })
            .then((result) => {
                if (result.value) {
                this.isload =true
                this.deleteOwners(ulidUser)
                }
            })
        },
        deleteOwners(ulidUser){
                axios.put(`${this.main_api_url}/sites/${this.ulid}`,{
                    remove_owner:ulidUser
                })
                .then(resAddOwner => {
                    resAddOwner
                    this.$swal(
                        'Supprimé!',
                        'L\'utilisateur a bien été supprimé des propriétaires du site',
                        'success'
                    )
                    this.ownersInUsers=[]
                    this.getSite(this.ulid)
                })
                .catch(errAddOwner => console.log(errAddOwner))
        },
        // image
        saveImage(ulidSite,ulidImage){
            this.showpageLoader = true
            axios.put(`${this.main_api_url}/sites/${ulidSite}`, {
                ulidimage: ulidImage,
            })
            .then(()=> {
                window.location.reload()
            })
            .catch(err => console.log ('errorPUT', err))
        },
        // router
        gotoSites(){
            this.$router.push({
                name:"applications"
            })
        },
        getMyinfos(){
            axios.get(`${this.main_api_url}/myinfos`)
            .then(resGetMyInfos => {
                this.owner_ulid =resGetMyInfos.data.ulid
            })
            .catch(err =>{
                console.error(err);
            })
        }
    },
    async created(){
        // call users in store/users
        await this.$store.dispatch('getUsers')
        this.users = this.$store.state.users;
        this.ulid = this.$route.params.ulid
        await this.$route.params.ulid?await this.getSite(this.ulid):0
    },
    beforeMount(){
    },
    async mounted(){
        this.getMyinfos() //pour mettre l'ulid user dans la requête
        if(this.$route.params.ulid){
            this.getSite(this.$route.params.ulid)
        }else{
            this.ploader=false
        }
    }
}
</script>
<style scoped>
.table-striped tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>